.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	.checkbox label:before {
		background: transparent !important;
		top: 5px;
		border: 1px solid #b5240f;
		outline: none;
	}

	.checkbox label:after {
		top: 5px;
		color: #b5240f !important;
	}

	.checkbox input[type=checkbox]:focus+label:before {
		outline: none;
	}

}