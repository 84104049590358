.parallax-v2 {
	position: relative;
	background-image: url(RESOURCE/img/parallax.jpg);
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 460px;

	@media (max-width:1199px) {
		background-attachment: inherit;
	}

	@media (max-width:767px) {
		height: 250px;
	}

	/*@media (max-width:992px) {

		height: auto;
		padding-top: 100%;

	}*/

	.container {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;

		.parallax-box {
			padding: 20px;
			min-height: 400px;
			padding: 50px;
			position: relative;
			z-index: 1;
			background-color: var(--color-primary);
			color: var(--white);
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: cover;
			max-width: 800px;
			border-radius: 8px;

			@media (max-width:767px) {
				padding: 30px;
			}

			.title {
				color: var(--color-white);

			}

			.text {
				color: var(--color-white);

				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&:before,
	&:after {
		content: "";
		background-color: #fff;
		background-color: var(--white);
		height: 10px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		content: "";

		@media (max-width:992px) {
			display: none;
		}

	}

	&:after {
		bottom: -1px;
	}
}