.static-view {
	margin-top: var(--page-margin-top);

	&.bergbahn-v1 {

		.bergbahnen-box {
			position: relative;

			.bergbahnen-img {
				position: absolute;
				top: 10px;
				right: 0;

				@media (max-width: 767px) {
					display: none;
				}

				img {
					max-width: 150px;
				}
			}
		}

		.gallery-container {
			background-image: url(RESOURCE/img/darkbg-repeat.jpg);

			.rowGallery {
				display: flex;
				padding: 10px 0;

				@media (max-width: 767px) {
					flex-direction: column;
				}

				.gallery-row {
					width: 50%;

					@media (max-width: 767px) {
						width: 100%;
					}

					&:nth-child(1) {
						.gallery-col {
							background-image: url(RESOURCE/img/bergbahn_img_01.jpg);
							height: 320px;
							background-repeat: no-repeat;
							border: 10px solid #fff;
							margin: 10px;
							background-position: center;

							@media (max-width: 767px) {
								margin: 10px 0;
							}
						}
					}
				}

				&:nth-child(1) {
					.gallery-full-rw {
						&:nth-child(1) {
							.gallery-col {
								background-image: url(RESOURCE/img/bergbahn_img_03.jpg);
								height: 150px;
								background-repeat: no-repeat;
								border: 10px solid #fff;
								margin: 10px;
								background-position: -20px -20px;

								@media (max-width: 767px) {
									margin: 10px 0;
									background-position: -90px -20px;
								}
							}
						}

						&:nth-child(2) {
							display: flex;
							color: #fff;
							font-size: 30px;
							align-items: center;

							@media (max-width: 767px) {
								flex-direction: column;
							}

							.gallery-col {
								&:nth-child(1) {
									background-image: url(RESOURCE/img/bergbahn_img_02.jpg);
									height: 150px;
									background-repeat: no-repeat;
									border: 10px solid #fff;
									margin: 10px;
									margin-right: 70px;
									width: 160px;
									background-position: center;

									@media (max-width: 767px) {
										margin: 10px 0;
										width: 100%;

									}
								}
							}
						}
					}


				}

			}

		}
	}

	&.oberstdorfer-v1,
	&.hornerbahnen-v1 {
		.bergbahnen-box {
			position: relative;

			.bergbahnen-img {
				position: absolute;
				top: 10px;
				right: 0;

				.para-logo {
					position: absolute;
					bottom: -230px;
					left: 40px;
					max-width: 190px;

					@media (max-width: 1500px) {
						bottom: -211px;
						left: 37px;
						max-width: 160px;
					}

					@media (max-width: 1199px) {
						position: absolute;
						bottom: auto;
						left: -120px;
						max-width: 92px;
						top: 0;
					}

					@media (max-width: 767px) {
						left: -90px;
						max-width: 75px;
					}

					@media (max-width: 576px) {
						left: -70px;
						max-width: 70px;
					}


				}



				@media (max-width: 992px) {
					top: -30px;
				}

				img {
					max-width: 250px;

					@media (max-width: 1500px) {
						max-width: 210px;
					}

					@media (max-width: 1200px) {
						max-width: 100px;
						top: -10px;
					}

					@media (max-width: 992px) {
						max-width: 110px;
					}

					@media (max-width: 767px) {
						max-width: 80px;
					}
				}
			}
		}

		.gallery-container {
			background-image: url(RESOURCE/img/darkbg-repeat.jpg);

			.rowGallery {
				display: flex;
				padding: 10px 0;

				@media (max-width: 767px) {
					flex-direction: column;
				}

				.gallery-row {
					width: 50%;

					@media (max-width: 767px) {
						width: 100%;
					}

					&:nth-child(1) {
						.gallery-col {
							background-image: url(RESOURCE/img/oberstdorfer_img01.jpg);
							height: 320px;
							background-repeat: no-repeat;
							border: 10px solid #fff;
							margin: 10px;
							background-position: center;

							@media (max-width: 767px) {
								margin: 10px 0;
							}
						}
					}
				}

				&:nth-child(1) {
					.gallery-full-rw {
						&:nth-child(1) {
							.gallery-col {
								background-image: url(RESOURCE/img/oberstdorfer_img03.jpg);
								height: 150px;
								background-repeat: no-repeat;
								border: 10px solid #fff;
								margin: 10px;
								background-position: -20px -20px;

								@media (max-width: 767px) {
									margin: 10px 0;
									background-position: -90px -20px;
								}
							}
						}

						&:nth-child(2) {
							display: flex;
							color: #fff;
							font-size: 30px;
							align-items: center;

							@media (max-width: 767px) {
								flex-direction: column;
							}

							.gallery-col {
								&:nth-child(1) {
									background-image: url(RESOURCE/img/oberstdorfer_img02.jpg);
									height: 150px;
									background-repeat: no-repeat;
									border: 10px solid #fff;
									margin: 10px;
									margin-right: 70px;
									width: 160px;
									background-position: center;

									@media (max-width: 767px) {
										margin: 10px 0;
										width: 100%;

									}
								}
							}
						}
					}


				}

			}

		}
	}

	&.uberuns-v1 {
		margin-top: var(--page-margin-top);

		.team-pic {
			img {
				width: 100%;
			}
		}

		.gallery-container {

			background-image: url(RESOURCE/img/darkbg-repeat.jpg);

			.gallery-col-main {
				display: flex;
				width: 100%;

				@media (max-width: 767px) {
					flex-direction: column;
					padding: 20px;
				}

				.gallery-col {
					width: 100%;
					margin: 20px 10px;
					border: 10px solid #fff;

					@media (max-width: 767px) {
						padding: 10px;
						margin: 20px 0;
					}

					/*img {
						width: 100%;
						object-fit: cover;
						border: 8px solid #fff;
					}*/

					&:nth-child(1) {
						width: 50%;
						background-image: url(RESOURCE/img/ub_img01.jpg);
						height: 320px;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;

						@media (max-width: 767px) {
							width: 100%
						}
					}

					&:nth-child(2) {
						width: 16%;
						background-image: url(RESOURCE/img/ub_img02.jpg);
						background-repeat: no-repeat;
						background-position: -150px 0;

						@media (max-width: 767px) {
							height: 320px;
							width: 100%;
						}

						img {
							height: 100%;
						}
					}

					&:nth-child(3) {
						width: 33.33%;
						background-image: url(RESOURCE/img/ub_img03.jpg);
						background-repeat: no-repeat;
						background-position: -110px -30px;
						height: 140px;

						@media (max-width: 767px) {
							width: 100%;
							background-position: -58px -20px;
						}
					}
				}
			}
		}

		.warum-bl {
			background: var(--color-primary);
			color: var(--color-white);
			padding: 50px;
			border-radius: 8px;

			@media (max-width: 767px) {
				padding: 30px;
			}

			.title {
				color: var(--color-white);
			}
		}
	}

	&.umgebung-v1 {
		margin-top: var(--page-margin-top);

		.heading1 {
			margin-bottom: 10px;
		}

		h3 {
			color: var(--color-red-dark);
		}

		.list-heart {
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 767px) {
				flex-wrap: wrap;
			}

			li {
				display: inline-block;
				background-image: url(RESOURCE/img/icon-heart.png);
				text-align: center;
				height: 141px;
				width: 154px;
				margin: 8px;
				font-weight: 700;
				font-size: 20px;
				line-height: 25px;
				color: var(--color-white);
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				height: 150px;
				width: 165px;
				background-size: cover;
				background-repeat: no-repeat;
			}
		}

		.btn-primary {
			max-width: 60%;
			justify-content: center;
			width: 100%;

			@media (max-width: 767px) {
				max-width: 100%;
			}
		}
	}

	.peer-container {
		display: flex;
		flex-direction: row-reverse;

		@media (max-width: 767px) {
			flex-direction: column;
		}

		.col-half {
			width: 50%;
			padding: 0 15px;

			@media (max-width: 767px) {
				width: 100%;
				margin-bottom: 1rem !important;
			}

			img {
				width: 100%;
			}
		}
	}

	&.anreise-v1 {
		margin-top: var(--page-margin-top);
	}
}